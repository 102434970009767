import {
  ExtensionRounded,
  ReceiptLongRounded,
  StoreMallDirectoryRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShopInfo } from "../../../../redux/shop";
import PlanTable from "../Table/Plan/PlanTable";

const TabShopInfo = () => {
  const shopInfo = useSelector((state) => state.shop);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <Grid container spacing={2}>
      {shopInfo.shopDomain.length > 0 && (
        <>
          <Grid item xs={5}>
            <Card>
              <Box padding={4}>
                <List>
                  <ListItem sx={{ marginBlockEnd: 2 }}>
                    <ListItemAvatar>
                      <Avatar>
                        <StoreMallDirectoryRounded color="action" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      va
                      primary={shopInfo.shopDomain}
                      secondary={
                        <MDBadge
                          badgeContent={shopInfo.install ? "Install" : "Uninstall"}
                          color={shopInfo.install ? "success" : "error"}
                        />
                      }
                    ></ListItemText>
                  </ListItem>
                  <ListItem sx={{ marginBlockEnd: 2 }}>
                    <ListItemAvatar>
                      <Avatar>
                        <ExtensionRounded color="action" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Theme App Extension"
                      secondary={
                        <MDBadge
                          badgeContent={shopInfo.themeAppStatus ? "Active" : "Deactivate"}
                          color={shopInfo.themeAppStatus ? "success" : "error"}
                        />
                      }
                    ></ListItemText>
                  </ListItem>
                  <ListItem sx={{ marginBlockEnd: 2 }}>
                    <ListItemAvatar>
                      <Avatar>
                        <ReceiptLongRounded color="action" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Plan Subcription"
                      secondary={
                        <Box>
                          <MDBadge badgeContent={shopInfo.plan} />
                          <MDBadge badgeContent={shopInfo.planType} color="dark" />
                          {shopInfo.trialDays && (
                            <MDBadge
                              badgeContent={`${shopInfo.trialDays} trial day`}
                              color="light"
                            />
                          )}
                        </Box>
                      }
                    ></ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card>
              <Box padding={2}>
                <PlanTable />
              </Box>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TabShopInfo;
