import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";

const PreviewCountdown = ({ countdown }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const countdownTime = new Date(countdown.countdown_time).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownTime - now;

      if (distance < 0) {
        clearInterval(interval);
      }

      setTimeLeft(distance);
    }, 1000);

    setIntervalId(interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  const second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24;

  const days = Math.floor(timeLeft / day);
  const hours = Math.floor((timeLeft % day) / hour);
  const minutes = Math.floor((timeLeft % hour) / minute);
  const seconds = Math.floor((timeLeft % minute) / second);

  return (
    <ul className="deco-countdown-list">
      <li>
        <span
          className="deco-countdown-item deco-countdown-days"
          style={{
            color: countdown.number_color,
          }}
        >
          {days < 10 ? `0${days}` : days}
        </span>
        <span
          className="deco-countdown-day-label"
          style={{
            color: countdown.label_color,
          }}
        >
          {countdown.label_day}
        </span>
      </li>
      <li>
        <span
          className="deco-countdown-item deco-countdown-hours"
          style={{
            color: countdown.number_color,
          }}
        >
          {hours < 10 ? `0${hours}` : hours}
        </span>
        <span
          className="deco-countdown-hour-label"
          style={{
            color: countdown.label_color,
          }}
        >
          {countdown.label_hour}
        </span>
      </li>
      <li>
        <span
          className="deco-countdown-item deco-countdown-minutes"
          style={{
            color: countdown.number_color,
          }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}
        </span>
        <span
          className="deco-countdown-minute-label"
          style={{
            color: countdown.label_color,
          }}
        >
          {countdown.label_minute}
        </span>
      </li>
      <li>
        <span
          className="deco-countdown-item deco-countdown-seconds"
          style={{
            color: countdown.number_color,
          }}
        >
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
        <span
          className="deco-countdown-second-label"
          style={{
            color: countdown.label_color,
          }}
        >
          {countdown.label_second}
        </span>
      </li>
    </ul>
  );
};

PreviewCountdown.propTypes = {
  countdown: PropTypes.shape({
    countdown_time: PropTypes.string.isRequired,
    number_color: PropTypes.string.isRequired,
    number_font_size: PropTypes.number.isRequired,
    label_color: PropTypes.string.isRequired,
    label_font_size: PropTypes.number.isRequired,
    label_day: PropTypes.string.isRequired,
    label_hour: PropTypes.string.isRequired,
    label_minute: PropTypes.string.isRequired,
    label_second: PropTypes.string.isRequired,
    time_out_action: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fixed_time: PropTypes.number,
  }).isRequired,
};

export default PreviewCountdown;
