import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Marketing = () => {
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Call API to get partners
    setLoading(false);
    setPartners([
      {
        logo: "https://pl-app.smartifyapps.com/assets/Sherpas-0662284c.webp",
        name: "Partner 1",
        utm: "https://mui.com/material-ui/react-button/",
        description: "Description",
        position: 1,
      },
      {
        logo: "https://pl-app.smartifyapps.com/assets/Sherpas-0662284c.webp",
        name: "Partner 2",
        utm: "https://mui.com/material-ui/react-button/",
        description: "Description",
        position: 2,
      },
    ]);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box marginTop={2}>
        <Card>
          <CardHeader></CardHeader>
          <CardContent>
            <Box marginBottom={2}>
              <Typography variant="h5" component="h5">
                Partner Marketing
              </Typography>
            </Box>
            <Stack gap={2}>
              {partners.map((partner, index) => (
                <Box
                  key={index}
                  sx={{ border: "1px solid rgb(177 177 177 / 87%)", borderRadius: 5, padding: 2 }}
                >
                  <Stack direction="row">
                    <Stack gap={2} sx={{ maxWidth: "15%", marginRight: 2 }}>
                      <Box sx={{ aspectRatio: 1, width: "100%" }}>
                        <img width="100%" src={partner.logo} alt={partner.name} />
                      </Box>
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        sx={{ color: "#ffffff" }}
                      >
                        Upload files
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(event) => console.log(event.target.files)}
                          multiple
                        />
                      </Button>
                    </Stack>
                    <Stack gap={2} width="100%">
                      <TextField label="Name" />
                      <TextField label="Description" />
                      <TextField label="Link UTM" />
                      <TextField label="Position" />
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </DashboardLayout>
  );
};

export default Marketing;
