import { Save, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShopInfo, resetError, logoutShop, setError } from "../../redux/shop";
import { checkShopifyDomain } from "../../Utils/Ultility";

const Technical = () => {
  const [shopDomain, setShopDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const shop = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const inputRef = useRef([]);

  const handleChangeShop = useCallback((event) => {
    if (checkShopifyDomain(event.target.value)) {
      setShopDomain(event.target.value);
      dispatch(resetError());
    } else {
      setShopDomain(event.target.value);
      dispatch(setError());
    }
  }, []);

  const handleKeyPressEnter = useCallback(
    (event) => {
      if (event.key === "Enter") {
        setLoading(true);
        dispatch(getShopInfo(shopDomain)).then((res) => setLoading(false));
        inputRef.current[1].blur();
      }
    },
    [shopDomain, inputRef]
  );

  const handleSearch = useCallback(() => {
    setLoading(true);
    dispatch(getShopInfo(shopDomain)).then((res) => setLoading(false));
  }, [shopDomain]);

  const handleSave = useCallback(() => {
    console.log("Save");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box marginTop={2}>
        <Card>
          <CardHeader></CardHeader>
          <CardContent>
            <Box marginBottom={2}>
              <TextField
                fullWidth
                placeholder="Search shopify domain"
                value={shopDomain}
                onChange={handleChangeShop}
                onKeyDown={handleKeyPressEnter}
                inputRef={(ref) => (inputRef.current[1] = ref)}
                disabled={shop.shopDomain.length > 0}
                helperText={shop.error ? "Invalid shopify domain" : ""}
                error={shop.error}
                InputProps={
                  shop.shopDomain.length === 0 && {
                    endAdornment: (
                      <InputAdornment>
                        <IconButton color="info" onClick={handleSearch}>
                          {loading ? <CircularProgress size={20} color="info" /> : <Search />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                }
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={12}>
                <Typography variant="h5" component="h5">
                  Collection Page
                </Typography>
                <Box sx={{ marginTop: 2 }}>
                  <Stack gap={2}>
                    <TextField label="pisRegex (link)" />
                    <TextField label="lpsPath (path)" />
                    <TextField label="pistRegex (title)" />
                    <TextField label="pispRegex (price)" />
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} md={12}>
                <Typography variant="h5" component="h5">
                  Product Page
                </Typography>
                <Box sx={{ marginTop: 2 }}>
                  <Stack gap={2}>
                    <TextField label="piRegex (image)" />
                    <TextField label="lpPath (path)" />
                    <TextField label="pitRegex (title)" />
                    <TextField label="pipRegex (price)" />
                    <TextField label="pibRegex (below image)" />
                    <TextField label="trustRegex (trust badge)" />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: 2 }}>
              <Box marginBottom={1}>
                <Typography variant="h5" component="h5">
                  Custom css & script
                </Typography>
              </Box>
              <Stack gap={2}>
                <TextField label="Custom css" multiline rows={5} />
                <TextField label="Custom script" multiline rows={5} />
              </Stack>
            </Box>
          </CardContent>
          <Stack direction="row" justifyContent="flex-start" padding={2}>
            <Button
              variant="contained"
              endIcon={<Save />}
              onClick={handleSave}
              sx={{ color: "#ffffff" }}
            >
              Save
            </Button>
          </Stack>
        </Card>
      </Box>
    </DashboardLayout>
  );
};

export default Technical;
