import { IconButton, Card, Typography, Stack, Avatar, Box, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React, { act, useCallback, useEffect, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../../../../../redux/labels";

const columns = [
  { Header: "plan", accessor: "plan", align: "left" },
  { Header: "action", accessor: "action", align: "left" },
  { Header: "action at", accessor: "actionAt", align: "left" },
  { Header: "code", accessor: "code", align: "left" },
];
const PlanTable = () => {
  const shopInfo = useSelector((state) => state.shop);
  const rows =
    shopInfo?.plans?.length > 0
      ? shopInfo?.plans?.map(({ plan, planType, price, action, actionAt, code }) => {
          return {
            plan: (
              <Stack direction="row" spacing={1}>
                <Typography variant="h6">{price ? `${plan} - ${price}` : plan}</Typography>
                <MDBadge color="dark" badgeContent={planType} />
              </Stack>
            ),
            action: (
              <MDBadge
                badgeContent={action}
                color={
                  action == "install"
                    ? "success"
                    : action == "uninstall"
                    ? "error"
                    : action == "start"
                    ? "info"
                    : action == "cancel"
                    ? "warning"
                    : "secondary"
                }
              />
            ),
            actionAt: <Typography variant="h6">{actionAt}</Typography>,
            code: <Typography variant="h6">{code ? code : ""}</Typography>,
          };
        })
      : [];
  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox pt={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        ></DataTable>
      </MDBox>
    </Card>
  );
};

export default PlanTable;
