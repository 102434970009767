import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

function ModaltrustBadge({ active, onClose, dataIndex }) {
  const trustBadges = useSelector((state) => state.trustBadge.list);
  const [trustBadge, setTrustBadge] = useState({});
  const [loading, setLoading] = useState(true);
  const [condition, setCondition] = useState({});

  useEffect(() => {
    setLoading(true);
    setTrustBadge(trustBadges[dataIndex]);
    setCondition(trustBadges[dataIndex]?.condition);
  }, [dataIndex, trustBadges]);

  useEffect(() => {
    setLoading(false);
  }, [trustBadge]);

  const conditionApply = {
    "specific-product": "Selected product pages",
    "all-product": "All product pages",
  };

  const grayCollor = {
    "grayscale(0%)": "Default color",
    "grayscale(100%)": "Black and white",
  };

  const countdownTimeEnd = {
    do_nothing: "Do nothing",
    close: "Hide banner",
    loop: "Loop countdown",
  };

  return (
    <Modal open={active} onClose={onClose}>
      <Box sx={style}>
        {loading ? (
          <Box>
            <CircularProgress color="info" />
          </Box>
        ) : (
          <>
            <Typography>{`Label: ${trustBadge?.name}`}</Typography>
            <Box sx={{ marginTop: 2 }}>
              <Stack spacing={2}>
                {trustBadge?.icons?.map((icon, index) => {
                  (icon.title || icon.link) && (
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">`Icon ${index}: `</Typography>
                      <Typography variant="body2" color="tomato">
                        {icon.title}
                      </Typography>
                      <Typography variant="body2" color="tomato">
                        {icon.link}
                      </Typography>
                    </Stack>
                  );
                })}
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Position:</Typography>
                  <Typography variant="body2" color="tomato">
                    {trustBadge?.position_type == "auto"
                      ? conditionApply[trustBadge?.condition?.product_apply]
                      : `Custom position, ${trustBadge?.position}`}
                  </Typography>
                </Stack>
                {trustBadge?.position_type == "auto" &&
                  trustBadge?.condition?.product_apply == "specific-product" && (
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">Product:</Typography>
                      <Stack>
                        {trustBadge?.condition?.include_variants?.map((item, index) => {
                          return (
                            <Typography variant="body2" color="tomato" key={index}>
                              {`- ${item.title}`}
                            </Typography>
                          );
                        })}
                      </Stack>
                    </Stack>
                  )}
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Arrangement: </Typography>
                  <Typography variant="body2" color="tomato">
                    {trustBadge?.direction}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Device:</Typography>
                  {trustBadge?.condition?.device.map((item, index) => {
                    return (
                      <MDBadge
                        key={index}
                        badgeContent={item}
                        color="success"
                        variant="gradient"
                        size="sm"
                      />
                    );
                  })}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Max icon per line - Desktop: </Typography>
                  <Typography variant="body2" color="tomato">
                    {trustBadge?.number_column_desktop == "auto-fit"
                      ? "auto"
                      : trustBadge?.number_column_desktop}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Max icon per line - Mobile: </Typography>
                  <Typography variant="body2" color="tomato">
                    {trustBadge?.number_column_mobile == "auto-fit"
                      ? "auto"
                      : trustBadge?.number_column_mobile}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Background color: </Typography>
                  <Typography variant="body2" color="tomato">
                    {grayCollor[trustBadge?.filter]}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Gray color: </Typography>
                  <Typography variant="body2" color="tomato">
                    {trustBadge?.background}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Animation: </Typography>
                  <Typography variant="body2" color="tomato">
                    {trustBadge?.animation}
                  </Typography>
                </Stack>
                {trustBadge?.alt && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">Alt text: </Typography>
                    <Typography variant="body2" color="tomato">
                      {trustBadge?.alt}
                    </Typography>
                  </Stack>
                )}
                {trustBadge?.use_countdown == 1 && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">When time ends: </Typography>
                    <Typography variant="body2" color="tomato">
                      {countdownTimeEnd[trustBadge?.countdown?.time_out_action]}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

ModaltrustBadge.defaultProps = {
  active: true,
  onClose: () => {},
  dataIndex: 0,
};

ModaltrustBadge.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataIndex: PropTypes.number,
};

export default ModaltrustBadge;
